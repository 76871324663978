import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/layout.js";
import dfinity from "../../images/dfinity-logo.jpg";
import NotCoinbase from "../../components/NotCoinbase";
import { BlogImage } from "../../components/BlogComponents";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <a href="https://dfinity.org" style={{
      "borderBottom": "0"
    }}>
  <BlogImage src={dfinity} mdxType="BlogImage">https://dfinity.org</BlogImage>
    </a>
    <p>{`Happy New Year, and I hope everyone has a better year than 2020!`}</p>
    <p>{`I have an exciting update to share - December 31st marked my last day at `}<NotCoinbase mdxType="NotCoinbase" />{`, and am joining the DFINITY Foundation!`}</p>
    <p>{`I'm looking forward to directly tackling the barriers to revitalizing the internet and freeing access from the walled gardens of AWS, Google Cloud, and Azure, and I'll be working to make sure that the future of the web is open, accessible, and friendly to people who are curious, or just getting started.`}</p>
    <h2 {...{
      "id": "a-bit-of-background"
    }}><a parentName="h2" {...{
        "href": "#a-bit-of-background"
      }}>{`A bit of background`}</a></h2>
    <p>{`If you're wondering what DFINITY is actually doing, I'll do my best to break it down for you, at 3 levels of technical familiarity.`}</p>
    <h5 {...{
      "id": "to-my-grandma"
    }}><a parentName="h5" {...{
        "href": "#to-my-grandma"
      }}>{`To my Grandma:`}</a></h5>
    <p>{`DFINITY is a non-profit organization that has built a new software program. Their goal isn't to make money by selling it as a product or a service, but to make that technology as useful as possible. If people really like it, it will improve their endowment.`}</p>
    <p>{`We are building something called the Internet Computer Protocol. It's not important how it works, but it provides an alternative way to build websites and mobile apps without relying on Amazon, or Google. Don't worry, they're still paying me in real money!`}</p>
    <h5 {...{
      "id": "to-someone-a-bit-more-savvy"
    }}><a parentName="h5" {...{
        "href": "#to-someone-a-bit-more-savvy"
      }}>{`To someone a bit more savvy:`}</a></h5>
    <p>{`DFINITY has built a protocol to allow anyone to run distributed cloud software. Like Bitcoin, it handles any updates in a way where you don't need to know or trust whoever is running the hardware, which is pretty neat.`}</p>
    <p>{`More importantly, the Internet Computer Protocol is built to scale infinitely with fast communications and updates. It means that you'll realistically be able to build applications without handing your money to big tech, and instead simply compensating an independent data center for the costs of running their computer.`}</p>
    <h5 {...{
      "id": "to-an-interested-engineer"
    }}><a parentName="h5" {...{
        "href": "#to-an-interested-engineer"
      }}>{`To an interested engineer:`}</a></h5>
    <p>{`Okay, this is where it gets a bit more complicated. The ICP has a similar concept as the Ethereum network. You can use blockchain not only to keep a record of transactions, but to run useful code, or distributed applications (dapps). The ICP takes that concept, and has poured a ton of research and development into building a much more performant process, which can enable an application to process updates asyncronously in as little as a few seconds.`}</p>
    <p>{`You're not going to be running a competitive shooter on the network anytime soon, but it's a fairly revolutionary opportunity to build things that can afford a few seconds to update to run at real enterprise-scale levels that won't go down along with `}<inlineCode parentName="p">{`aws-east`}</inlineCode>{`.`}</p>
    <p>{`In order to support this network, they have a conceptual model of "canisters", which execute web assembly. You can use Rust or C++ to target the same build target, but DFINITY has also written a new language called Motoko that is tailored to run on the ICP and support all of the best practices for taking advantages of the ICP by default.`}</p>
    <h2 {...{
      "id": "what-ill-be-doing-there"
    }}><a parentName="h2" {...{
        "href": "#what-ill-be-doing-there"
      }}>{`What I'll be doing there`}</a></h2>
    <p>{`I'll be joining the developer experience team. DFINITY has been working hard to make the foundations of the technology ready for prime time, and now they are turning to focus on making it pleasant to use. In addition to learning Motoko and gaining familiarity with the current state of the project, I'll be working on documentation, building dashboards, and example projects to make it easier to get started.`}</p>
    <p>{`Stay tuned, because I plan to write about my learning process as I go on this new journey!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      